import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import current_newsletter from '../../newsletters/kofc1056Newsletter202306.pdf';

class NewsPage extends React.Component {
  render() {
    return (
      <Container className="vh-100 px-0" fluid={true}>
        <Row className="vh-100 px-0">
          {/* <Col md="2" /> */}
          <Col md="12">
            <embed src={current_newsletter} type="application/pdf" width="100%" height="100%" />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default NewsPage;