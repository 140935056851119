import React from 'react';
import golf_outing from '../../images/events/GolfOuting.png';

class GolfPage extends React.Component {
  render() {
    return (
      <>
        <a href="https://www.tickettailor.com/events/knightsofcolumbuscouncil1056/1066869">
          <img src={golf_outing} className="img-fluid rounded" alt="Golf Outing 2024" />
        </a>
        <h2>
          <a href="https://www.tickettailor.com/events/knightsofcolumbuscouncil1056/1066869">
            <h2>Purchase Tickets for the Event</h2>
          </a>
        </h2>

      </>
    );
  }
}

export default GolfPage;