
import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import EventsService from '../../services/EventsService';


class UpcomingEvents extends React.Component {

  render() {
    const events = EventsService.getUpcomingEvents(6);
    console.log("Upcoming Events", events);
    return (

      <Card>
        <Card.Header as="h5">Upcoming Events</Card.Header>
        <Card.Body>
          <div>
            {
              events.map((e, i) => (
                <li className="d-flex align-items-start knight-link" key={e.id}>
                  {(e.href?.startsWith("/"))
                    ? <Link to={e.href} className="knight-link">{`${e.start?.toFormat("LLL dd")} - ${e.title}`}</Link>
                    : <a href={e.href} className="knight-link">{`${e.start?.toFormat("LLL dd")} - ${e.title}`}</a>
                  }
                </li>
              ))
            }
          </div>
        </Card.Body>
      </Card>

    );
  }
}

export default UpcomingEvents;