import React from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import raffle from '../../images/events/Raffle.png';

class PurseBingo extends React.Component {
  render() {
    return (
      <>
        <Container className="vh-100 px-0" fluid={true}>
          <Row>
            <Col md="12">
              <h3>Knights of Columbus - Council 1056 Online Auction</h3>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <br />&nbsp;<br />&nbsp;
            </Col>
          </Row>
          <Row>
            <Col xs="4">
              <a href="https://designerpursebingokofc1056.winningbidder.com/event/3422">
                <img src={raffle} className="raffle-image" alt="Online Raffle" />
              </a>
            </Col>
            <Col xs="8" lg="4" className='text-navy'>
              <h1>Need a break from it all?<br /><br/>Want a taste of Italy?</h1>
            </Col>
            <Col xs="8" lg="4">
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <br />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <h3>Then take a look at our Purse Bingo for Charity online silent auction!</h3>

            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <h4 className='text-info'>Our online auction is now officially open!</h4>
              You do not need to be present at the Purse Bingo on October 4th to win.<br/>&nbsp;
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <a href="https://designerpursebingokofc1056.winningbidder.com/event/3422">
                <h3>Click here to go to the Online Auction Site</h3>
              </a>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default PurseBingo;