import React from 'react';
import { Calendar, luxonLocalizer, Views } from 'react-big-calendar';
import { DateTime, Settings } from 'luxon';
import EventsService from '../../services/EventsService';
// only use `Settings` if you require optional time zone support
Settings.defaultZone = 'America/New_York'

// Luxon uses the Intl API, which currently does not contain `weekInfo`
// to determine which weekday is the start of the week by `culture`.
const localizer = luxonLocalizer(DateTime, { firstDayOfWeek: 7 })
const calendarEvents = EventsService.getEventsForCalendar();

class CalendarPage extends React.Component {
  render() {
    return (
      <>
        <h2>Events for Council 1056</h2>

        <Calendar
          localizer={localizer}
          events={calendarEvents}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          views={[Views.MONTH, Views.DAY]}
          defaultView={Views.MONTH}
          onSelectEvent={this.onSelectEvent}
        />
      </>
    );
  }

  onSelectEvent(e: any) {
    if (e && e.id) {
      const event = EventsService.getEventById(e.id);
      console.log("Event", event);
      if (event && event.href && event.href?.startsWith("http")) {
        window.open(event.href, "_blank");
      } else if (event && event.href && event.href?.startsWith("/")) {
        window.location = event.href;
      }
    }
  }

}

export default CalendarPage;