
import { DateTime } from 'luxon';
import CalendarEvents from "../CalendarEvents.json";


class EventsService {
  public static events = CalendarEvents;

  public static getUpcomingEvents(numberOfEvents: number = 6) {
    return this.getAllEvents()?.filter((e: { start: DateTime; }) => e.start >  DateTime.local()).slice(0, numberOfEvents);
  }

  public static getEventsForCalendar() {
    const calendarEvents: any[] = [];

    for (let e of this.getAllEvents()) {
      calendarEvents.push({
        id: e.id,
        start: e.start?.toJSDate(),
        end: e.end?.toJSDate(),
        allDay: e.allDay,
        title: e.title
      })
    }

    return calendarEvents;
  }

  public static getEventById(eventId: number) {
    const events = this.getAllEvents()?.filter((e) => e.id === eventId);
    return events?.length > 0 ? events[0] : undefined;
  }

  public static getAllEvents(): any[] {
    let events: any[] = [];

    let id: number = 0;
    for (let e of EventsService.events) {
      let dtStart: DateTime = DateTime.fromISO(e.start);
      let dtEnd = e.end ? DateTime.fromISO(e.end) : undefined;
      if (!dtStart.isValid) {
        console.error(`Unable to parse date: ${e.start} in events`, e);
      } else {
        dtStart = DateTime.fromISO(e.start);
        if (!dtEnd || !dtEnd?.isValid) {
          dtEnd = dtStart;
        }

        const allDay: boolean = dtStart.equals(dtEnd);
        let titleWithTime = e.title;
        if (!allDay && dtStart && dtStart.hour !== 0) {
          titleWithTime = `${titleWithTime} - ${dtStart.toFormat("ha")?.toLowerCase()}`;
        }

        const evt: any = {
          id: id++,
          start: dtStart,
          end: dtEnd,
          allDay: allDay,
          title: titleWithTime,
          fullTitle: e.title,
          href: e.href
        }
        events.push(evt);
      }
    }
    //console.log("Events", events);
    return events;
  }

}

export default EventsService;