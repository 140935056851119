
import { DateTime } from 'luxon';
import React from 'react';

const Footer = () => (
    <footer className="footer">
        <p>Knights of Columbus - Council 1056 | 21 S. Henry St, Delaware, OH 43015 | Copyright &copy; {DateTime.now().year}</p>
    </footer>
);

export default Footer;