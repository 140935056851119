import { Link, Route, Routes } from 'react-router-dom';
import Footer from "./components/common/Footer";
import Header from './components/common/Header';
import Home from "./components/common/Home";

import 'font-awesome/scss/font-awesome.scss';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import './App.scss';
import CalendarComponent from './components/events/CalendarPage';
import GolfPage from './components/events/GolfPage';
import NewsPage from './components/events/NewsPage';
import PurseBingo from './components/events/PurseBingo';
import Raffle from './components/events/Raffle';


function App() {

  // const Home = lazy(() => import('./routes/Home'));
  // const About = lazy(() => import('./routes/About'));

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home/>} />
        <Route path="/golf" element={<GolfPage/>} />
        <Route path="/purse" element={<PurseBingo/>} />
        <Route path="/pursebingo" element={<PurseBingo/>} />
        <Route path="/purse-bingo" element={<PurseBingo/>} />
        <Route path="/charity-purse-bingo" element={<PurseBingo/>} />
        <Route path="/calendar" element={<CalendarComponent/>} />
        <Route path="/news" element={<NewsPage/>} />
        <Route path="/raffle" element={<Raffle />} />
      </Routes>
      <Footer />
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Home</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default App;