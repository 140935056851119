import React from 'react';

import purse_banner from '../../images/events/PurseBingoPoster.png';

class PurseBingo extends React.Component {
  render() {
    return (
      <>
      <a href="https://www.tickettailor.com/events/knightsofcolumbuscouncil1056/1317263">
        <h2>Purchase Tickets for the Event</h2>
      </a>
      <a href="https://www.tickettailor.com/events/knightsofcolumbuscouncil1056/1317263">
        <img src={purse_banner} className="text-center w-50" alt="Purse Bingo" />
      </a>
      </>
    );
  }
}

export default PurseBingo;